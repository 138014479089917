import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useGoal } from "gatsby-plugin-fathom";

function ShareButton () {
    const recordSocialShareSuccess = useGoal('OAGHCXJ1');
    const recordSocialShareFailure = useGoal('CMCPEJEV');
    const onClick = useCallback(async () => {
        const shareData = {
            title: "Healthy Pup Match",
            // On IOS the text will be shared, not the URL. We want the URL to be shared.
            // text: "A match two game powered by your wearable fitness devices.",
            url: "https://healthypupmatch.com"
        };
        try {
            await navigator.share(shareData);
            recordSocialShareSuccess();
        } catch(err) {
            console.error(err);
            recordSocialShareFailure();
        }
    });
    return (
        <button
            type="button"
            onClick={onClick}
            className="block w-full rounded-md border border-transparent px-5 py-2 hover:bg-purple-800 bg-purple-700 text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 sm:px-10"
        >
            Invite a Friend
        </button>
    );
}

ShareButton.propTypes = {};

export default ShareButton;
