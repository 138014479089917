import React, { Fragment } from "react";
import PropTypes from "prop-types";
import navitems from "../data/navitems.js";

function Footer() {
    return (
        <footer className="bg-gray-50">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    {navitems.map((item) => (
                        <div key={item.name} className="px-5 py-2">
                            <a href={item.href}
                                target={item.target ? item.target : null}
                                rel={item.target ? "noreferrer" : null}
                                className="text-base text-gray-500 hover:text-gray-900">
                                {item.name}
                            </a>
                        </div>
                    ))}
                </nav>
                <p className="mt-8 text-center text-base text-gray-400">
                    &copy; {(new Date().getFullYear())} All rights reserved.
                </p>
            </div>
        </footer>
    )
}

Footer.propTypes = {};

export default Footer;
