import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  InboxIcon,
  PuzzleIcon,
  UserGroupIcon,
  DeviceMobileIcon,
  SparklesIcon,
  ChevronDoubleRightIcon,
  ClockIcon,
} from '@heroicons/react/outline';
import ShareButton from '../ShareButton';

const puzzleScreenImagePath = '../../images/healthy-pup-match-mockup.png';
const multiplayerScreenImagePath = '../../images/healthy-pup-match-multiplayer-mockup.png';

export default function Content() {
  return (
    <div className="relative bg-white pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-purple-800">
                  <DeviceMobileIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  A puzzle game powered by your wearable fitness devices!
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Healthy Pup Match uses the data from your favorite wearable fitness devices to reward and motivate you in-game!
                </p>
                <div className="text-lg text-gray-500">
                  <div className="mt-6">
                    <dl>
                      <div className="relative pb-5">
                        <dt>
                          <PuzzleIcon className="absolute h-8 w-8 text-purple-500" aria-hidden="true" />
                          <p className="ml-12 text-lg leading-12 font-medium text-gray-900">Match 2 Puzzles</p>
                        </dt>
                        <dd className="mt-2 ml-12 text-base text-gray-500">Stay sharp by solving challenging and rewarding Match 2 puzzles.</dd>
                      </div>
                      <div className="relative pb-5">
                        <dt>
                          <SparklesIcon className="absolute h-8 w-8 text-purple-500" aria-hidden="true" />
                          <p className="ml-12 text-lg leading-12 font-medium text-gray-900">Rewards</p>
                        </dt>
                        <dd className="mt-2 ml-12 text-base text-gray-500">Gain rewards when meeting fitness goals, such as daily steps.</dd>
                      </div>
                      <div className="relative pb-5">
                        <dt>
                          <ChevronDoubleRightIcon className="absolute h-8 w-8 text-purple-500" aria-hidden="true" />
                          <p className="ml-12 text-lg leading-12 font-medium text-gray-900">Step Tracking</p>
                        </dt>
                        <dd className="mt-2 ml-12 text-base text-gray-500">No more buying lives and moves to keep playing, just go for a walk and track your steps!</dd>
                      </div>
                      <div className="relative pb-5">
                        <dt>
                          <ClockIcon className="absolute h-8 w-8 text-purple-500" aria-hidden="true" />
                          <p className="ml-12 text-lg leading-12 font-medium text-gray-900">Fitness Devices</p>
                        </dt>
                        <dd className="mt-2 ml-12 text-base text-gray-500">Works with <b>Fitbit</b> and <b>Apple Watch</b> devices.</dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 sm:mt-16 lg:mt-0 mx-auto max-w-xl lg:mx-0">
            <div className="pl-4 pr-4 lg:pr-0 lg:-mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <StaticImage
                placeholder="blurred"
                layout="constrained"
                src={puzzleScreenImagePath}
                width={400}
                height={711}
                className="rounded-md shadow-2xl ring-1 ring-black ring-opacity-5"
                alt="Picture of mobile phone with puzzle match game."
              />
              {/* <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
                alt="Inbox user interface"
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-md lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-purple-800">
                  <UserGroupIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Healthy Competition
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Stay motivated by competiting with friends. In-game rewards, as well as some healthy competition with friends helps you stay motivated to meet your fitness goals!
                </p>
                <div className="mt-6">
                  <ShareButton />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 sm:mt-8 lg:mt-0 lg:col-start-1 mx-auto max-w-xl lg:mx-0">
            <div className="px-4 sm:px-6 lg:px-0 md:-ml-18 lg:-ml-0 lg:relative lg:h-full">
              <StaticImage
                placeholder="blurred"
                layout="constrained"
                src={multiplayerScreenImagePath}
                width={400}
                height={711}
                className="rounded-md shadow-2xl ring-1 ring-black ring-opacity-5"
                alt="Picture of mobile phone with puzzle match game."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
