import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { ChevronRightIcon } from '@heroicons/react/solid';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Footer from '../components/Footer';
import Masthead from '../components/index/Masthead';
import Content from '../components/index/Content';

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[]}
        title='Fitness inspired puzzle match game'
      />

      <main>

        <Masthead />
        
        <Content />

      </main>

      <Footer />

    </Layout>
  );
}

export default IndexPage;
