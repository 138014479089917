import React, { Fragment, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import classNames from 'classnames';
import { ChevronRightIcon, StarIcon } from '@heroicons/react/solid';
import { StarIcon as StarIconOutline } from '@heroicons/react/outline';
import { StaticImage } from 'gatsby-plugin-image';
import { useGoal } from 'gatsby-plugin-fathom';
import ShareButton from '../ShareButton';

import iconImage from '../../images/favicon/android-chrome-192x192.png';

const iconImagePath = '../../images/favicon/android-chrome-512x512.png';
const mastheadImagePath = '../../images/healthy-pup-match-mockup.png';
const mastheadImagePathMobile = '../../images/mobile-asset.png';

const states = ['IDLE', 'ERROR', 'SUBMITTING', 'SUBMITTED']
    .reduce((prev, current) => ({
        ...prev,
        [current]: current
    }), {});

const isAlreadySubscriberErrorMessage = (erorrMessage) => {
    return erorrMessage.indexOf('is already subscribed') !== -1;
};

const EmailForm = () => {
    const [currentState, setStatus] = useState(states.IDLE);
    const [errorMessage, setErrorMessage] = useState(null);
    const inputRef = useRef(null);
    const recordSignupSuccessEvent = useGoal('LXONCRRV');
    const recordSignupFailureEvent = useGoal('WXREPS3Y');
    const recordDuplicateSignupEvent = useGoal('GY9UAYLP');

    const defaultErrorMessage =
        'We weren&apos;t able to complete your signup. Please double check that the provided email is correct.';

    const onSubmit = useCallback(async (event) => {
        setStatus(states.ERROR);
        event.preventDefault();
        const inputElem = inputRef.current;
        const emailValue = inputElem.value;
        try {
            setStatus(states.SUBMITTING);
            const response = await addToMailchimp(emailValue);
            const { result, msg } = response;
            if (result === 'error'
                && !isAlreadySubscriberErrorMessage(msg)) {
                setStatus(states.ERROR);
                setErrorMessage(defaultErrorMessage);
            } else {
                if (isAlreadySubscriberErrorMessage(msg)) {
                    recordDuplicateSignupEvent();
                } else {
                    recordSignupSuccessEvent();
                }
                setStatus(states.SUBMITTED);
                setErrorMessage(null);
            }
        } catch (e) {
            console.error(e);
            recordSignupFailureEvent();
            setStatus(states.ERROR);
            setErrorMessage(defaultErrorMessage);
        }
    }, [
        addToMailchimp,
        setStatus,
        recordSignupSuccessEvent,
        recordSignupFailureEvent,
        recordDuplicateSignupEvent
    ]);

    const isInputDisabled =
        currentState == states.SUBMITTING ||
        currentState == states.SUBMITTED;

    const isButtonDisabled =
        currentState == states.SUBMITTING ||
        currentState == states.SUBMITTED;

    let inputAttrs = {};

    if (currentState === states.ERROR) {
        inputAttrs = {
            ...inputAttrs,
            "aria-invalid": true,
            "aria-describedby": "email-error"
        };
    }

    const FormContent = (<form action="#" onSubmit={onSubmit} className="sm:flex">
        <div className="min-w-0 flex-1">
            <label htmlFor="hero-email" className="sr-only">
                Email address
            </label>
            <input
                {...inputAttrs}
                disabled={isInputDisabled}
                ref={inputRef}
                id="hero-email"
                type="email"
                className={classNames(
                    {
                        "opacity-60": isInputDisabled
                    },
                    "block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                )}
                placeholder="Enter your email"
            />

            {errorMessage !== null ? (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                    We weren&apos;t able to complete your signup. Please double check that the provided email is correct.
                </p>
            ) : null}

            {currentState == states.SUBMITTED ? (
                <p className="rounded p-4 bg-purple-200 border-2 border-purple-500 mt-2 text-sm text-purple-800 font-bold" id="email-success">
                    Thank you for signing up!
                </p>
            ) : null}
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-3">
            <button
                type="submit"
                className={classNames({
                    "opacity-60": isButtonDisabled,
                    "hover:bg-green-800": !isButtonDisabled,
                },
                    "block w-full rounded-md border border-transparent px-5 py-3 bg-green-700 text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:px-10"
                )}
                disabled={isButtonDisabled}
            >
                {(() => {
                    switch (currentState) {
                        case states.SUBMITTED:
                            return "Submitted";
                        case states.SUBMITTING:
                            return "Submitting";
                        default:
                            return "Notify me"
                    }
                })()}
            </button>
        </div>
    </form>);

    const NAVIGATOR_SHARE_SUPPORTED = 
        typeof window !== `undefined` &&
        typeof navigator !== `undefined` &&
        typeof window["navigator"]["share"] === `function`;

    const ShareButtonContent = NAVIGATOR_SHARE_SUPPORTED 
    ? (
        <div className="mt-4">
            <ShareButton />
        </div>
    ) : null;

    return (
        <div className="mt-6 sm:max-w-xl">
            {currentState == states.SUBMITTED ? (
                <p className="mt-6 rounded p-4 bg-purple-200 border-2 border-purple-500 mt-2 text-sm text-purple-800 font-bold" id="email-success">
                    Thank you for signing up!
                </p>
            ) : (
                <div className="sm:max-w-lg sm:w-full">
                    <div className="sm:flex flex-col">
                        {FormContent}
                        {ShareButtonContent}
                    </div>
                    <div className="mt-6 text-xs text-gray-500">
                        <p>We use Mailchimp as our marketing platform. By clicking above to signup, you acknowledge that your information will be transferred to Mailchimp for processing.
                            {" "}
                            <a
                                className="text-purple-700 hover:text-purple-800"
                                href="https://mailchimp.com/legal/" target="_blank" rel="noreferrer">
                                Learn more about Mailchimp&apos;s privacy practices here.
                            </a>
                        </p>
                        <br />
                        <p>You can unsubscribe at any time by clicking the link in the footer of our emails.</p>
                    </div>
                </div>
            )}
        </div>
    );
};

const IconImage = ({ image }) => {
    return (
        <div>
            <img
                className="h-16 w-auto rounded"
                src={image}
                alt="Workflow"
            />
        </div>
    );
};

IconImage.propTypes = {
    image: PropTypes.string
};

export default function Masthead() {
    const titleText = "Healthy Pup Match is launching soon!";
    const subtitleText = "A match 2 game powered by your wearable fitness devices!";
    return (
        <div className="pb-16">
            <div className="overflow-hidden sm:pt-12 lg:relative">
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
                    <div>
                        {/* <IconImage image={iconImage} /> */}
                        <div className="mt-8">
                            <div className="inline-flex space-x-4">
                                <span className="rounded bg-purple-50 px-2.5 py-1 text-xs font-semibold text-purple-600 tracking-wide uppercase">
                                    New
                                </span>
                                <span className="inline-flex items-center text-sm font-medium text-purple-600 space-x-1">
                                    <span>Sign up below for updates</span>
                                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                            </div>
                            <div className="block lg:hidden mt-4">
                                <StaticImage
                                    placeholder="blurred"
                                    layout="constrained"
                                    src={mastheadImagePathMobile}
                                    width={400}
                                    height={200}
                                    className="rounded-md shadow-xl ring-1 ring-black ring-opacity-5"
                                    alt="Picture of mobile phone with puzzle match game."
                                />
                            </div>
                            <div className="mt-6 sm:max-w-xl">
                                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                    {titleText}
                                </h1>
                                {/* <h2 className="mt-6 text-xl text-gray-500">
                                    {subtitleText}
                                </h2> */}
                                <div className="text-gray-500">
                                    {/* <ul className="pl-8 pt-4 list-disc">
                                        <li>Ran out of moves? Go on a walk! No more paying for lives.</li>
                                        <li>Compatible with Fitbit and Apple Watch devices.</li>
                                    </ul> */}
                                    <p className="mt-6 lg:mt-12 text-lg">
                                        Launching on iOS and Android soon!{" "}
                                        <span className="font-medium">
                                            Sign up for early access!
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <EmailForm />
                        </div>
                    </div>
                </div>

                <div className="hidden lg:block sm:mx-auto sm:max-w-3xl sm:px-6">
                    <div className="py-8 sm:relative sm:mt-8 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                        <div className="relative pl-4 pr-4 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                            <StaticImage
                                placeholder="blurred"
                                layout="constrained"
                                src={iconImagePath}
                                width={400}
                                height={400}
                                className="ring-opacity-5"
                                alt="Picture of mobile phone with puzzle match game."
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
